/* Universal Styles */

html {
  font-size: 18px;
}

@media only screen and (max-width: 560px) {
  html {
    font-size: 15px;
  }
}

input:focus {
  outline: none;
}

/* Reusable Component - Image Container */

.image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  background: #000;
}

.image-container img {
  width: 100%;
}

/* App Styles */

.App {
  font-family: 'Work Sans', sans-serif;
}

/*h1 {
  padding: 10px 0;
  text-align: center;
  background-color: #cca353;
  font-family: Poppins, sans-serif;
  font-size: 1.8rem;
  color: #fff;
}*/

.tan-stripe {
  background-color: #cca353;
  height: 8px;
}

.rav-logo {
  text-indent: -9000px;
  background-image: url(ravenous-logo.svg);
  background-repeat: no-repeat;
  /*background-size: 100%;
  width: 100%;
  height: 0;
  padding-top: 3.63%;*/ /*Divide image height by width */
  width: 175px;
  height: 63px;
  margin: 0 auto;
  position: relative;
  top: 1rem;
  z-index: 1;
}

@media (min-width:768px) {
.rav-logo {
  width: 220px;
  height: 79.1634px;
}
}
