body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Universal Styles */

html {
  font-size: 18px;
}

@media only screen and (max-width: 560px) {
  html {
    font-size: 15px;
  }
}

input:focus {
  outline: none;
}

/* Reusable Component - Image Container */

.image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  background: #000;
}

.image-container img {
  width: 100%;
}

/* App Styles */

.App {
  font-family: 'Work Sans', sans-serif;
}

/*h1 {
  padding: 10px 0;
  text-align: center;
  background-color: #cca353;
  font-family: Poppins, sans-serif;
  font-size: 1.8rem;
  color: #fff;
}*/

.tan-stripe {
  background-color: #cca353;
  height: 8px;
}

.rav-logo {
  text-indent: -9000px;
  background-image: url(/static/media/ravenous-logo.6498c1b0.svg);
  background-repeat: no-repeat;
  /*background-size: 100%;
  width: 100%;
  height: 0;
  padding-top: 3.63%;*/ /*Divide image height by width */
  width: 175px;
  height: 63px;
  margin: 0 auto;
  position: relative;
  top: 1rem;
  z-index: 1;
}

@media (min-width:768px) {
.rav-logo {
  width: 220px;
  height: 79.1634px;
}
}

.BusinessList {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 4.4rem 10%;
}

.Business {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 16.66rem;
  margin: -63px .5rem 2.3rem .5rem;
  padding-bottom: 63px;
}

@media (min-width:768px) {
  .Business {
    margin: -79px .5rem 2.3rem .5rem;
    padding-bottom: 79px;
  }
}

.Business .image-container {
  height: 16.66rem;
  margin-bottom: 1rem;
}

.Business h2 {
  margin-bottom: .5rem;
  font-size: 1.2rem;
  font-weight: 600;
}

.Business-information {
  display: flex;
  justify-content: space-between;
}

.Business-information p {
  font-size: .88rem;
  font-weight: 300;
  line-height: 1rem;
}

.Business-address {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Business-reviews {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: right;
}

.Business-reviews h3 {
  color: #CCA353;
  font-weight: 600;
}

.Business-reviews .rating {
  font-size: .88rem;
  line-height: 1rem;
}

.SearchBar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: url(/static/media/background_search_desktop.902df4c5.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  height: 25rem;
  position: relative;
  top: -63px;
  z-index: 0;
  box-shadow: inset 0 -25px 25px 0 rgba(0,0,0, .6);
}

.SearchBar-sort-options ul {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  margin-bottom: 2.22rem;
  color: #ffffff;
}

@media (min-width:768px) {
  .SearchBar {
    top: -79px;
  }

  .SearchBar-sort-options ul {
    margin-top: 3rem;
  }
}

.SearchBar-sort-options li {
  cursor: pointer;
  width: 4.33rem;
  border-bottom: 1px solid #fff;
  padding: 0 2.58rem .33rem 2.58rem;
  line-height: 1.13;
  text-align: center;
  font-weight: 600;
  font-size: .83rem;
  transition: color .25s;
}

.SearchBar-sort-options li:hover {
  color: #d4cfcf;
}

.SearchBar-sort-options li.active,
.SearchBar-sort-options li.active:hover {
  border-bottom: 1px solid #f0c36c;
  color: #f0c36c;
}

.SearchBar-fields {
  display: flex;
  justify-content: center;
  margin-bottom: 2.88rem;
}

.SearchBar-fields input {
  width: 21rem;
  padding: .66rem 1rem;
  margin-right: 2.22rem;
  border: 1px solid #fff;
  border-radius: 4px;
  font-size: .77rem;
  font-weight: 500;
}

.SearchBar-fields input:last-child {
  margin-right: 0;
}

.SearchBar-submit {
  text-align: center;
}

.SearchBar-submit a {
  border-radius: 4px;
  padding: .72rem 1.7rem;
  background-color: #cca353;
  color: #ffffff;
  font-weight: 600;
  transition: background-color .5s;
}

.SearchBar-submit a:hover {
  cursor: pointer;
  background-color: #a7874b;
}

@media only screen and (max-width: 560px) {
  .SearchBar {
    background-image: url(/static/media/background_search_mobile.5d0905e6.jpg);
  }

  .SearchBar-sort-options ul {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .SearchBar-fields {
    flex-direction: column;
    align-items: center;
  }

  .SearchBar-fields input {
    margin-right: 0;
    margin-bottom: .86rem;
  }
}

